import React from "react"
import { graphql } from "gatsby"
import PageTypes from "../../../components/page-types/_PageTypes"
import Blocks from "../../../components//blocks/_Blocks"

const StoryblokDefaultTemplate = ({ data, pageContext }) => {
  const node = data.entry
  const content = JSON.parse(node.content)
  const parentContent = data.parentStartPage
    ? JSON.parse(data.parentStartPage.content)
    : []
  const inheritConfigContent =
    data.configInheritPage && pageContext.config_object
      ? JSON.parse(data.configInheritPage.content)
      : false

  // @patrick
  // in "inheritConfigContent" steckt der Inhalt (content) der Ansprechpartner
  if (inheritConfigContent) {
    console.info("Found inherit content", inheritConfigContent)
  }

  const subNavItems = pageContext.subNavData.map((subNavItem) => ({
    link: subNavItem.real_path,
    link_text: subNavItem.name,
  }))

  const breadcrumbs = pageContext.breadcrumbs
    .map((item) => ({ link: item?.real_path, link_text: item?.name }))
    ?.reverse()

  const contentBlocks = (content) => (
    <div>
      {content.body &&
        content.body.map((block) =>
          React.createElement(Blocks(block.component), {
            key: block._uid,
            block: block,
            type: block.component,
          })
        )}
    </div>
  )

  // console.log("Storyblok Content", content)
  return React.createElement(
    PageTypes(content.component),
    {
      key: node.uid,
      content: content,
      name: node.name,
      type: content.component,
      language: node.fields.language,
      breadcrumbs: breadcrumbs,
      subNavItems: subNavItems,
      parentContent: parentContent,
    },
    contentBlocks(content)
  )
}

export const query = graphql`
  query StoryblokDefaultTemplateQuery(
    $id: String!
    $parentStartPageId: Int
    $config_object: Int
  ) {
    entry: storyblokEntry(id: { eq: $id }) {
      content
      name
      fields {
        language
      }
    }
    parentStartPage: storyblokEntry(internalId: { eq: $parentStartPageId }) {
      content
    }
    configInheritPage: storyblokEntry(internalId: { eq: $config_object }) {
      content
    }
  }
`

export default StoryblokDefaultTemplate
